import numeral from 'numeral';
import { addMonths, format, addDays, parseISO, compareAsc } from 'date-fns';
import { InstallationTimeCodes, New_InstallationTimeCodes } from '../types';

const formatMoney = (num: number) => numeral(num).format('$0,0.00');

const formatMoneyAutoDecimal = (num: number) => numeral(num).format('$0,0[.]00');

const formatDate = (date: Date | null) => {
  return date == null ? '' : format(date, 'do MMMM yyyy');
};

const formatMoneyWithoutSign = (num: number) => (num % 1 == 0 ? num.toString() : numeral(num).format('0,0.00'));

const formatMoneyTwoDecimalPlaces = (num: number) => numeral(num).format('0,0.00');

const formatMoneyWithoutSignSplit = (num: number) =>
  numeral(num)
    .format('0,0.00')
    .split('.');

const formatMoneyWithoutSignOrComma = (num: number) => (num % 1 == 0 ? num.toString() : numeral(num).format('0.00'));

const EffectiveMonthlyDateAfterOfferPricing = (months: string, startFrom?: Date | null): Date | null => {
  const monthsNumber = parseInt(months);
  if (!monthsNumber) {
    return null;
  } else {
    const date = addMonths(startFrom && !isNaN(startFrom.getTime()) ? startFrom : new Date(), monthsNumber);
    return date;
  }
};

const formatEffectiveMonthlyDateAfterOfferPricing = (months: string, startFrom?: Date | null): string => {
  const date = EffectiveMonthlyDateAfterOfferPricing(months, startFrom);
  return date == null ? '' : format(date, 'do MMMM yyyy');
};

const reformatEndDateOfActiveCampaign = (date: string): string => {
  const dateObject = parseISO(date);
  const endDate = addDays(dateObject, 1);
  return format(endDate, 'do MMMM yyyy');
};

const formatMoneyFree = (num: number) => (num === 0 ? 'Free' : formatMoney(num));

const capitalize = (str: string | undefined | null) => {
  return (str ?? '')
    .split(' ')
    .map(str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase())
    .join(' ');
};

const getDateFromDuration = (months: string, startFrom?: Date | null) => {
  const monthsNumber = parseInt(months);
  if (!monthsNumber) {
    return '';
  } else {
    const date = addMonths(startFrom && !isNaN(startFrom.getTime()) ? startFrom : new Date(), monthsNumber);
    const endDate = addDays(date, 1);
    return format(endDate, 'do MMMM yyyy');
  }
};

/**
 * Format phone numbers
 * is0800 = false => Format numbers like '092810122' into '09 281 0122'
 * is0800 = true => Format numbers like '0800759759' into '0800 759 759'
 * @param number string
 * @param is0800 boolean
 * @returns
 */
const formatPhoneNumber = (number: string, is0800: boolean) => {
  let matcher = is0800 ? /^(\d{4})(\d{2})(\d{2})(\d{2})$/ : /^(\d{2})(\d{3})(\d{4})$/;
  //Check if the input is of correct length
  let matchArr = number.match(matcher);
  if (!matchArr) {
    return null;
  }
  return matchArr[1] + ' ' + matchArr[2] + ' ' + matchArr[3] + ' ' + matchArr[4];
};

/**
 * Compares coupon duration and campaign end date, returns the last date of offer and formats it
 *
 * @param couponDuration
 * @param campaignEndDate
 * @param startFrom
 * @returns
 */

const formatDateOrDuration = (
  couponDuration?: string,
  campaignEndDate?: string,
  startFrom?: Date | null
): string | null => {
  if (!couponDuration && !campaignEndDate) {
    return null;
  }
  const monthsNumber = couponDuration ? parseInt(couponDuration) : false;
  if (!monthsNumber) {
    return campaignEndDate ? reformatEndDateOfActiveCampaign(campaignEndDate) : null;
  }
  if (!campaignEndDate) {
    return couponDuration ? getDateFromDuration(couponDuration, startFrom) : null;
  }
  const dateLeft = addMonths(startFrom ?? new Date(), monthsNumber);
  const dateRight = campaignEndDate ? parseISO(campaignEndDate) : new Date();
  const result = compareAsc(dateLeft, dateRight);
  const endDate = addDays(result > 0 ? dateLeft : dateRight, 1);
  return format(endDate, 'do MMMM yyyy');
};

const GetTechBookingSlotRangeByCode = (slot: string) => {
  switch (slot) {
    case 'AM':
      return InstallationTimeCodes.AM;
    case 'AM1':
      return InstallationTimeCodes.AM1;
    case 'AM2':
      return New_InstallationTimeCodes.AM2;
    case 'PM':
      return InstallationTimeCodes.PM;
    case 'PM1':
      return New_InstallationTimeCodes.PM1;
    case 'PM2':
      return InstallationTimeCodes.PM2;
    case 'ALLDAY':
      return New_InstallationTimeCodes.ALLDAY;
    default:
      return '';
  }
};

export {
  capitalize,
  formatMoney,
  formatMoneyAutoDecimal,
  formatMoneyFree,
  formatMoneyWithoutSign,
  formatMoneyWithoutSignSplit,
  formatMoneyWithoutSignOrComma,
  EffectiveMonthlyDateAfterOfferPricing,
  formatEffectiveMonthlyDateAfterOfferPricing,
  reformatEndDateOfActiveCampaign,
  formatPhoneNumber,
  formatDateOrDuration,
  GetTechBookingSlotRangeByCode,
  formatDate,
  formatMoneyTwoDecimalPlaces,
};
